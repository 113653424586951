@use '@vg-constellation/angular-16/styles/c11n';
@use '@vg-constellation/angular-16/styles/components/table';
@use '@vg-constellation/angular-16/styles/components/link';
@use '@vg-constellation/angular-16/styles/variables/tokens';
@use '@vg-constellation/angular-16/styles/functions';
@use './styles/custom-c11n/icons';
@use './styles/custom-c11n/lists';
@use './styles/custom-c11n/radio';
@use './styles/text';
@use './styles/headings';
@use './styles/containers';
@use './styles/utils';
@use './styles/interactions';
@use '@vg-constellation/angular-16/styles/breakpoints';
@use './styles/breakpoint-names';

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    align-items: stretch;
    margin: 0;
}

.global-footer {
    margin-top: auto;
}

.zci-margin-top {
    margin-top: functions.rem(tokens.$c11n-size-space-10x);

    @include breakpoints.media-breakpoint-down(breakpoint-names.$md) {
        margin-top: functions.rem(tokens.$c11n-size-space-4x);
    }
}

// styling for sticky footer
vg-vgn-nav {
    display: flex;
    flex-flow: column nowrap;
    min-height: 100vh;
}

main {
    flex: 1;
}
// end of styling for sticky footer
